export const ESCROWS_LIMIT = 10;
export const SAAS_ESCROWS_LIMIT = 10;
export const DEPOSITS_PER_PAGE = 20;
export const ASSETS_LIMIT = 10;
export const LEGACY_REPOS_LIMIT = 10;
export const USER_INTEGRATIONS_LIMIT = 100;
export const UPLOAD_SIZE_LIMIT = Math.pow(2, 30) * 30; // 30GB
export const EXCLUSIVE_OFFER = 40;
export const REPORTS_LIMIT = 20;
export const CUSTODIAN_INTEGRATION_LIMIT = 10;
export const BACKUPS_LIMIT = 10;
export const ESCROW_MEMBERS_LIMIT = 50;
export const NOTIFICATIONS_LIMIT = 10;
export const CUSTODIAN_PROFILES_LIMIT = 10;
export const USERS_LIMIT = 10;

export const PROVIDER_KEY_TO_NAME = {
  azure: 'Azure',
  bitbucket: 'Bitbucket',
  gcp: 'GCP',
  gitlab: 'Gitlab',
  github: 'Github',
  github_server: 'Github Enterprise',
  gitlab_server: 'Gitlab Private',
  codecommit: 'Amazon Codecommit',
  aws: 'Amazon S3',
  share_file_https: 'Sharefile',
  azure_devops: 'Azure DevOps',
  bitbucket_server: 'Bitbucket Server',
  beanstalk: 'Beanstalk',
};

export const PROVIDERS_WITH_USERNAME = ['gitlab_server', 'github_server', 'bitbucket_server', 'beanstalk'];

export const REPORTS_START_DATE = '2024-06-01';

export enum OtherEscrowStatusEnum {
  TerminationRequested = 'terminationRequested',
  TerminationRequestedSuggester = 'terminationRequestedSuggester',
  TerminationRequestedReceiver = 'terminationRequestedReceiver',
  SignedNoDepositsDepositor = 'signedNoDepositsDepositor',
  SignedNoDepositsBeneficiary = 'signedNoDepositsBeneficiary',
  SignedWithDeposits = 'signedWithDeposits',
  RequestingChanges = 'requestingChanges',
  ChangesRequested = 'changesRequested',
  ChangesRequestedSuggester = 'changesRequestedSuggester',
  ChangesRequestedReceiver = 'changesRequestedReceiver',
  AwaitingSignature = 'awaitingSignature',
  ActivationProcess = 'activationProcess',
}

export enum OtherSaasEnvironmentStatusEnum {
  TerminationRequested = 'terminationRequested',
}

export enum OtherCustodianProfileStatusEnum {
  TerminationRequested = 'terminationRequested',
}
